import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Euribor = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was ist eigentlich der EURIBOR?" showCalc={false} />
            <Article>
                <p>
                    Wenn du gerade auf der Suche nach einer Eigentumswohnung oder einem Haus bist, werden die täglichen
                    Entwicklungen auf dem Finanzmarkt plötzlich interessant für dich. Vielleicht bist du bei deinen
                    Recherchen bereits auf den Begriff EURIBOR gestoßen und fragst dich, was das denn eigentlich genau
                    bedeutet. Im folgenden Beitrag erfährst du es.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wofür steht EURIBOR?</h2>
                <p>
                    Euribor ist die Abkürzung für Euro Interbank Offered Rate. Es handelt sich dabei um einen Leitzins,
                    der von der Europäischen Zentralbank vorgegeben wird. Der durchschnittliche Zinssatz ist für alle
                    Kreditinstitute sehr wichtig. Zu diesem schließen Kreditinstitute nämlich Geschäfte untereinander
                    ab. Das bedeutet, dass der Leitzins eines der wichtigsten Finanzinstrumente ist, das der Zentralbank
                    zur Verfügung steht. Weil dieser Zinssatz die{" "}
                    <Link to="/artikel/konditionen/" target="_blank" rel="noreferrer noopener">
                        Konditionen
                    </Link>{" "}
                    angibt, zu denen Banken sich Geld von der Zentralbank besorgen können, bestimmt er auch, wie die
                    Rendite der Bank in Zukunft aussehen werde. An der Höhe von Leitzinsen wie dem Euribor orientieren
                    sich außerdem auch die Zinsen für{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kredite
                    </Link>
                    . Deshalb ist er auch für dich von Bedeutung.
                </p>
                <hr />

                <h2>Wie wird der EURIBOR berechnet?</h2>
                <p>
                    Für die Berechnung des Euribor-Zinssatzes wird die Marktsituation beobachtet. Die Höhe wird in
                    erster Linie durch Angebot und Nachfrage am Markt bestimmt, aber auch externe Faktoren wie das
                    Wirtschaftswachstum oder die{" "}
                    <Link to="/artikel/inflation/" target="_blank" rel="noreferrer noopener">
                        Inflation
                    </Link>{" "}
                    beeinflussen die Höhe der Zinssätze. Durch die Zinssätze europäischer Kreditinstitute wird der
                    aktuelle Wert des Euribor bestimmt. Um ihn zu berechnen, werden die 15 höchsten und niedrigsten
                    Werte aus der Berechnung herausgenommen, um das Ergebnis nicht zu verzerren. Er ist ein
                    durchschnittlicher Zinssatz und wird durch aktuelle Zinsen von 57 europäischen Banken berechnet.
                    Jeden Tag um ca. 11 Uhr mitteleuropäischer Zeit wird der aktuelle Euribor von der EZB
                    veröffentlicht.
                </p>
                <p>
                    Wenn Banken Kredite vergeben, verlangen sie zum Euribor-Zinssatz meistens einen Aufschlag von 0,5
                    bis 2 Prozent. Das ist auch in Österreich der Fall, wo der Euribor der wichtigste{" "}
                    <Link to="/artikel/leitzins/" target="_blank" rel="noreferrer noopener">
                        Leitzins
                    </Link>{" "}
                    ist.
                </p>
                <hr />

                <h2>Wie kam es dazu? Die Geschichte von Euribor</h2>
                <p>
                    Das erste mal wurde der Euribor am 30. Dezember 1998 veröffentlicht. Damit haben die Verbände der
                    Kreditwirtschaft in Brüssel die Rechtsgrundlage für den gemeinsamen Referenzzinssatz geschaffen.
                    Seitdem bilden die Euribor-Sätze die Grundlage für den Preis und Zinssatz aller Arten von
                    Finanzprodukten wie Zinsswaps, Sparkonten oder Hypotheken. Der 1. Jänner 1999 war der Tag, an dem
                    der Euro als Währung eigeführt wurde. Davor gab es unterschiedliche Referenzzinssätze wie zB. den
                    PIBOR in Frankreich oder den Fibor in Deutschland.
                </p>
                <hr />

                <h2>Was du also wissen solltest</h2>
                <p>
                    Der Euribor beeinflusst alle variablen{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>
                    , die wir zahlen müssen. Sobald er also sinkt, sinken auch die Zinsen für Hypotheken und wenn der
                    Euribor steigt, steigen die Zinsen. Es gibt 15 Laufzeiten des Euribor, die von einer Woche bis zu
                    zwölf Monaten reichen. Das Gegenteil von Euribor ist der EONIA, der Euro Overnight Index Average,
                    der durch tatsächliche Umsätze festgelegt wird. Die aktuellen Euribor-Stände kannst du online
                    öffentlich einsehen. Zusammenfassend lässt sich sagen, dass der Euribor der wichtigste Leitzins
                    Österreichs ist, aber auch in vielen anderen EU-Ländern spielt er eine enorm wichtige Rolle. Er gilt
                    als mächtigstes Finanzinstrument der Europäischen Zentralbank. Mithilfe des Euribor kann einer
                    Inflation oder Deflation entgegengewirkt werden. Außerdem beeinflusst er die Rendite von Banken und
                    damit die gesamte europäische Wirtschaft enorm. Für dich wird er wichtig, sobald du eine Hypothek
                    aufnimmst und Zinsen zahlen musst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"euribor"}></BreadcrumbList>
            <ArticleStructuredData page={"euribor"} heading={"Was ist eigentlich der EURIBOR?"} />
        </Layout>
    );
};

export default Euribor;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.euribor", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
